<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Processos"
      :route="route"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      tipoRelatorio="relatorioProcessos"
      :fields="fields"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioProcessos',
  components: {
    GenericReport,
  },

  computed: {
    ...mapState([
      'configuracao', 'genericData',
    ]),
  },

  data() {
    return {
      route: 'report/processos',
      hideMateriaisGenerico: null,
      hideCarrinhoHorarioVan: null,
      fields: [],
      unidades: [],
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade/todas',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        id_sub_setor: {
          type: 'genericSelect',
          route: 'subSetor',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Área',
          },
        },
        id_tipo_processo: {
          type: 'genericSelect',
          route: 'tipoProcesso',
          defaultValue: 1,
          firstOption: {
            value: null,
            label: 'Processo',
          },
          customFilters: {
            id_tipo_processo: [],
          },
        },
        id_setor_destino: {
          type: 'genericSelect',
          route: 'setor',
          defaultValue: null,
          dependency: {
            key: 'id_tipo_processo',
            values: ['5'],
          },
          firstOption: {
            value: null,
            label: 'Setor Destino',
          },
        },
        id_motivo_retorno: {
          type: 'genericSelect',
          route: 'motivoRetorno',
          defaultValue: null,
          dependency: {
            key: 'id_tipo_processo',
            values: ['7'],
          },
          labelKey: 'motivo',
          firstOption: {
            value: null,
            label: 'Motivo Retorno',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(3, 'days'),
          defaultEndDate: moment(),
        },
        ciclo_urgencia: {
          type: 'select',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Ciclo de Urgência',
          },
          options: [
            {
              value: 'true',
              label: 'Sim',
              text: 'Sim',
            },
            {
              value: 'false',
              label: 'Não',
              text: 'Não',
            },
          ],
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
        id_tipo_material: {
          type: 'genericSelect',
          label: 'Tipo de Material',
          route: 'tipoMaterial',
        },
      },
    };
  },

  mounted() {
    if (this.genericData.unidade.length > 1) {
      this.sideFilters = {
        ...this.sideFilters,
        id_unidade_patrimonio: {
          type: 'genericSelect',
          label: 'Unidade Patrimonio',
          route: 'unidade/todas',
        },
      };
    }

    this.hideMateriaisGenerico = !!(this.configuracao.hideMateriaisGenerico
      && this.configuracao.hideMateriaisGenerico === 'true');

    if (!this.hideMateriaisGenerico) {
      this.sideFilters = {
        ...this.sideFilters,
        id_tipo_material_generico: {
          type: 'tipoMaterialGenerico',
          label: 'Tipo de Material Genérico',
        },
        descricaoGenerico: {
          type: 'descricaoGenerico',
          label: 'Descrição Material Genérico',
        },
        protocolo: {
          type: 'input',
          label: 'Protocolo',
        },
      };
    }

    this.hideCarrinhoHorarioVan = !!(this.configuracao.hideCarrinhoHorarioVan
      && this.configuracao.hideCarrinhoHorarioVan === 'true');

    if (!this.configuracao.hideCarrinhoHorarioVan) {
      this.sideFilters = {
        ...this.sideFilters,
        id_carrinho: {
          type: 'genericSelect',
          label: 'Número do carrinho',
          route: 'transporte/carrinho',
        },
        id_horario_van: {
          type: 'genericSelect',
          label: 'Horário da van',
          route: 'transporte/horarioVan',
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
